import { request } from "./index"

export type Batch = {
  BatchId: number
  AssignmentId: number
  Transactions: number
  Rejected: number
  Failed: number
  MessageId: string
  ScheduledDeliveryDate: Date
  DeliveredDate: Date
  ReceiptFileProcessed: Date
  TotalAmount: number
  ErrorAmount: number
  DeclinedAmount: number
  SumTransfer: number
}

export async function fetchBatches({ assignmentId }: { assignmentId: number }): Promise<Batch[]> {
  return request<Batch[]>({
    method: `GET`,
    endpoint: `Batch?assignmentId=${assignmentId}`,
  })
}
