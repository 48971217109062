import React from "react"
import { Signature } from "../../api/Signature"
import { Batch } from "../../api/Batch"

export const mapToStatus = (status: number) => {
  switch (status) {
    case 0:
      return `Mottatt`
    case 1:
      return `Påbegynt godkjenning`
    case 2:
      return `Godkjent`
    case 7:
      return `Kansellerer`
    case 8:
      return `Utført`
    case 9:
      return `Kansellert`
    default:
      return ``
  }
}

export const mapToDate = (date_: Date | string, time?: boolean): string => {
  if (date_) {
    const date = new Date(date_)
    let day = date.getUTCDate().toString()
    day = day.length === 1 ? 0 + day : day
    let month = (date.getUTCMonth() + 1).toString()
    month = month.length === 1 ? 0 + month : month
    const year = date.getUTCFullYear().toString()
    let hour = date.getUTCHours().toString()
    hour = hour.length === 1 ? 0 + hour : hour
    let minutes = date.getUTCMinutes().toString()
    minutes = minutes.length === 1 ? 0 + minutes : minutes
    return time ? day + `.` + month + `.` + year + ` ` + hour + `:` + minutes : day + `.` + month + `.` + year
  }
  return date_
}
