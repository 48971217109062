import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchSignatures, Signature } from "../../api/Signature";
import { ProgressIndicator } from "@dnb/eufemia";
import { mapToDate } from "./tableHelpers";
import ErrorModal from "../ErrorModal";

type Props = {
  assignmentId: number;
};

const SignaturesModal: React.FC<Props> = ({
  assignmentId,
}: {
  assignmentId: number;
}) => {
  const queryClient = useQueryClient();

  const signatures = useQuery<Signature[], Error>(
    `signatures`,
    () => fetchSignatures({ assignmentId }),
    {
      enabled: !!assignmentId,
    }
  );

  useEffect(() => {
    return () => {
      signatures?.remove();
    };
  }, []);

  const content = !signatures.error ? (
    <table className={`dnb-table`}>
      {signatures?.isLoading ? (
        <div className="Spinner">
          <ProgressIndicator />
        </div>
      ) : (
        <>
          <thead>
            <tr className={`dnb-table__tr`}>
              <th scope="col" className={` dnb-table__th`}>
                Navn
              </th>
              <th
                scope="col"
                align="right"
                className={` dnb-table--no-wrap`}
              ></th>
              <th
                scope="col"
                align="right"
                className={` dnb-table--no-wrap`}
              ></th>
              <th
                scope="col"
                align="right"
                className={` dnb-table--no-wrap`}
              ></th>

              <th scope="col" className={` dnb-table--no-wrap`}>
                Dato
              </th>
            </tr>
          </thead>
          <tbody>
            {signatures?.data ? (
              signatures?.data?.map((file) => (
                <tr
                  className="dnb-table__tr dnb-table__tr--even"
                  key={file.Name}
                >
                  <td colSpan={4}>{file.Name ? file.Name : `Ikke navngitt`}</td>
                  <td>{mapToDate(file.SignedDate)}</td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </>
      )}
    </table>
  ) : (
    <ErrorModal />
  );
  return content;
};

export default SignaturesModal;
