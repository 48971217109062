import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { submitSigningForm } from "../../api/SigningForm";
import { navigate } from "gatsby";

import {
  ProgressIndicator,
  Section,
  FormSet,
  FormRow,
  Button,
  FormStatus,
  H2,
} from "@dnb/eufemia";
type Props = {
  assignmentId: number;
};
const ApprovalModal: React.FC<Props> = ({
  assignmentId,
}: {
  assignmentId: number;
}) => {
  const queryClient = useQueryClient();
  const [errMessage, setErrMessage] = useState(``);

  const mutation = useMutation(submitSigningForm, {
    onSuccess: () => {
      queryClient.invalidateQueries(`signing`);
      navigate(`/login-complete`);
    },
    onError: (error: AxiosError) => {
      if ((error?.response?.status as number) === 500) {
        sessionStorage.clear();
        navigate(`/logout`);
      }
      setErrMessage(mapToErrorMessage(error?.response?.status as number));
    },
  });

  const mapToErrorMessage = (status: number): string => {
    if (status === 400) {
      return `Du har allerede signert på denne filen.`;
    } else if (status === 401) {
      return `Du har ikke godkjenningstilgang på denne filen.`;
    } else {
      return `En feil har oppstått. Vennligst logg inn på ny.`;
    }
  };

  return (
    <div>
      <H2 style={{ textAlign: `center` }} bottom="1.5rem">
        Bekreft at du godkjenner dette oppdraget
      </H2>
      <Section spacing style_type="mint-green">
        <div className="Spinner">
          <FormSet prevent_submit={true}>
            <FormRow>
              {!mutation.isLoading ? (
                !mutation.isError ? (
                  <Button
                    type="submit"
                    text="Jeg bekrefter"
                    onClick={() => {
                      mutation.mutate({
                        assignmentId: assignmentId,
                      });
                    }}
                    style={{ margin: `0 auto` }}
                  />
                ) : (
                  <FormStatus text={errMessage} />
                )
              ) : (
                <div className="Spinner">
                  <ProgressIndicator />
                </div>
              )}
            </FormRow>
          </FormSet>
        </div>
      </Section>
    </div>
  );
};

export default ApprovalModal;
