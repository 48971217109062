import { request } from "./index"

export interface SigningForm {
  assignmentId: number
}

export async function submitSigningForm(data: SigningForm) {
  return request<{ assignmentId: number }>({
    method: `POST`,
    endpoint: `Signature`,
    params: data,
  })
}

type SigningFormStatusDto = {
  Completed: boolean
  State: number
  Created: string
}

export type SigningFormState =
  | "Sent"
  | "Received"
  | "Processing"
  | "Approved"
  | "Rejected"
  | "Manual"
  | "SystemError"
  | "TimeOut"
  | "Delivered"

const statuses: SigningFormState[] = [
  `Received`,
  `Processing`,
  `Approved`,
  `Rejected`,
  `Manual`,
  `SystemError`,
  `TimeOut`,
  `Delivered`,
]

export interface SigningFormStatus {
  state: SigningFormState
  completed: boolean
  reference?: string
}

export async function fetchApplicationStatus(assignmentId: number): Promise<SigningFormStatus> {
  const status = await request<SigningFormStatusDto | null>({
    method: `GET`,
    endpoint: `/SignatureStatus`,
    params: { assignmentId: assignmentId },
  })

  return {
    state: status ? statuses[status.State] : `Sent`,
    completed: !!status && status.Completed,
  }
}
