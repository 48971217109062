import React from "react";
import { useQuery, useQueryClient } from "react-query";
import ErrorModal from "../ErrorModal";
import { mapToDate, mapToStatus } from "./tableHelpers";
import { fetchAssignments, Assignment } from "../../api/Assignment";

import ReadMoreModal from "./ReadMoreModal";
import SignaturesModal from "./SignaturesModal";
import ApprovalModal from "./ApprovalModal";
import CancelModal from "./CancelModal";
import "@dnb/eufemia/style";
import { Table, Modal, ProgressIndicator } from "@dnb/eufemia";

const NewTable = () => {
  const queryClient = useQueryClient();
  const assignments = useQuery<Assignment[], Error>(
    `assignments`,
    () => fetchAssignments(),
    { retry: false }
  );

  const content = !assignments.error ? (
    assignments?.isLoading ? (
      <ProgressIndicator size="large" className="ProgressIndicator" />
    ) : (
      <Table className="dnb-table NewTable">
        <caption>A Table Caption</caption>
        <thead>
          <tr>
            <th scope="col" className="dnb-table--no-wrap">
              Filnavn
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Mottaksdato
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Total sum
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Antall transaksjoner
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Antall avviste
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Detaljer
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Antall godkjent
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Godkjenn
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Status
            </th>
            <th
              scope="col"
              className="dnb-table--no-wrap"
              style={{ textAlign: `right` }}
            >
              Kanseller
            </th>
          </tr>
        </thead>
        <tbody>
          {assignments?.data && !assignments?.isLoading ? (
            assignments?.data
              ?.sort(function(a, b) {
                return (
                  new Date(b.CollectedDate).getTime() -
                  new Date(a.CollectedDate).getTime()
                );
              })
              .map((file) => {
                return (
                  <tr key={file.AssignmentId}>
                    <td>{file.MessageId}</td>
                    <td style={{ textAlign: `right` }}>
                      {mapToDate(file.CollectedDate, true)}
                    </td>
                    <td style={{ textAlign: `right` }}>
                      {file?.TotalAmount?.toLocaleString(`no-NO`, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: `right` }}>{file.Transactions}</td>
                    <td style={{ textAlign: `right` }}>{file.Rejected}</td>
                    <td style={{ display: `flex`, justifyContent: `flex-end` }}>
                      <Modal
                        title={file.MessageId}
                        trigger_text="Les mer"
                        align_content="center"
                        focus_selector=".dnb-input__input:first-of-type"
                      >
                        <ReadMoreModal assignmentId={file.AssignmentId} />
                      </Modal>
                    </td>
                    <td style={{ textAlign: `right` }}>
                      <Modal
                        trigger_variant="tertiary"
                        align_content="center"
                        trigger_icon={false}
                        title="Godkjenningshistorikk"
                        trigger_text={
                          file.Signatures + `/` + file.SignaturesNeeded
                        }
                        focus_selector=".dnb-input__input:first-of-type"
                      >
                        <SignaturesModal assignmentId={file.AssignmentId} />
                      </Modal>
                    </td>
                    <td style={{ display: `flex`, justifyContent: `flex-end` }}>
                      <Modal
                        trigger_text="Godkjenn"
                        focus_selector=".dnb-input__input:first-of-type"
                        disabled={
                          file.Signatures >= file.SignaturesNeeded ||
                          file.Status == 7 ||
                          file.Status == 9 ||
                          file.Status == 8
                        }
                      >
                        <ApprovalModal assignmentId={file.AssignmentId} />
                      </Modal>
                    </td>
                    <td style={{ textAlign: `right` }}>
                      {mapToStatus(file.Status)}
                    </td>
                    <td style={{ display: `flex`, justifyContent: `flex-end` }}>
                      <Modal
                        focus_selector=".dnb-input__input:first-of-type"
                        disabled={file.Status != 0}
                        trigger_icon="close"
                      >
                        <CancelModal assignmentId={file.AssignmentId} />
                      </Modal>
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </Table>
    )
  ) : (
    <ErrorModal error={assignments?.error} />
  );
  return content;
};

export default NewTable;
