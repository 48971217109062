import { request } from "./index"

export type Signature = {
  AssignmentId: number
  SignedDate: Date
  Name: string
}

export async function fetchSignatures({ assignmentId }: { assignmentId: number }): Promise<Signature[]> {
  return request<Signature[]>({
    method: `GET`,
    endpoint: `Signature?assignmentId=${assignmentId}`,
  })
}
