import React from "react"
import { Section } from "@dnb/eufemia/components"
import Layout from "../Layout"

type Props = {
  children: React.ReactNode
}

const Table = ({ children }: Props) => {
  return (
    <Layout>
      <Section className="Table" top="xx-large" style_type="white">
        <div>
          <h1>Filer</h1>
        </div>
      </Section>
      <Section>
        <div>{children}</div>
      </Section>
    </Layout>
  )
}

export default Table
