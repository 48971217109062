import React from "react"
import Table from "../components/Table/Table"
import { PageProps } from "gatsby"
import NewTable from "../components/Table/NewTable"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

function TablePage(props: PageProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Table>
        <NewTable />
      </Table>
    </QueryClientProvider>
  )
}

export default TablePage
