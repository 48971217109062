import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchBatches, Batch } from "../../api/Batch";
import { mapToDate } from "./tableHelpers";
import { ProgressIndicator } from "@dnb/eufemia";
import ErrorModal from "../ErrorModal";

type Props = {
  assignmentId: number;
};
const ReadMoreModal: React.FC<Props> = ({
  assignmentId,
}: {
  assignmentId: number;
}) => {
  const queryClient = useQueryClient();

  const batch = useQuery<Batch[], Error>(
    `batch`,
    () => fetchBatches({ assignmentId }),
    {
      enabled: !!assignmentId,
    }
  );

  useEffect(() => {
    return () => {
      batch?.remove();
    };
  }, []);

  const content = !batch.error ? (  
    <table className={`dnb-table`}>
      {batch.isLoading ? (
        <div className="Spinner">
          <ProgressIndicator />
        </div>
      ) : (
        <>
          <thead>
            <tr className="dnb-table--small">
              <th scope="col" className="dnb-table--no-wrap">
                Planlagt levering
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Total sum
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Antall transaksjoner
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Returkvittering mottatt
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                MeldingsId
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Feil antall
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Feil beløp
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Avvist antall
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Avvist beløp
              </th>
              <th
                scope="col"
                className="dnb-table--no-wrap"
                style={{ textAlign: `center` }}
              >
                Sum overført
              </th>
            </tr>
          </thead>
          <tbody>
            {batch?.data ? (
              batch?.data?.map((file) => (
                <tr key={file.BatchId}>
                  <td>{mapToDate(file.ScheduledDeliveryDate)}</td>
                  <td style={{ textAlign: `center` }}>
                    {file.TotalAmount.toLocaleString(`no-NO`)}
                  </td>
                  <td style={{ textAlign: `center` }}>{file.Transactions}</td>
                  <td style={{ textAlign: `center` }}>
                    {mapToDate(file.ReceiptFileProcessed, true)}
                  </td>
                  <td style={{ textAlign: `center` }}>{file.MessageId}</td>
                  <td style={{ textAlign: `center` }}>{file.Rejected}</td>
                  <td style={{ textAlign: `center` }}>{file.ErrorAmount}</td>
                  <td style={{ textAlign: `center` }}>{file.Failed}</td>
                  <td style={{ textAlign: `center` }}>{file.DeclinedAmount}</td>
                  <td style={{ textAlign: `center` }}>{file.SumTransfer}</td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </>
      )}
    </table>   
  ) : (
    <ErrorModal />
  );
  return content;
};

export default ReadMoreModal;
